













import Vue from 'vue'
import { AccordionBus } from '@/main'
export default Vue.extend({
  name: 'Accordion',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    group: {
      type: String,
      default: 'default'
    }
  },
  data: () => ({
    expanded: false,
    expandedGroup: 'default-bus',
    isThisEmitter: false
  }),
  created () {
    window.addEventListener('resize', this.sizeController)
  },
  destroyed () {
    window.removeEventListener('resize', this.sizeController)
    AccordionBus.$off()
  },
  async beforeMount () {
    this.expanded = this.open
    this.expandedGroup = this.group + '-bus'
    AccordionBus.$on(this.expandedGroup, this.close)
  },
  methods: {
    close () {
      if (this.isThisEmitter) {
        this.isThisEmitter = false
        return
      }
      this.expanded = false
      this.resizePanel()
    },
    sizeController () {
      if (!this.expanded) return
      this.resizePanel()
    },
    toggle: function () {
      this.expanded = !this.expanded
      this.resizePanel()
      this.isThisEmitter = true
      AccordionBus.$emit(this.expandedGroup)
    },
    resizePanel: function () {
      let height = '0px'
      const element = (this.$refs.content as HTMLElement)
      if (this.expanded) height = element.scrollHeight + 'px'
      element.style.maxHeight = height
    }
  },
  computed: {
    isOpen: function () {
      if (this.expanded) {
        return 'expanded'
      }
      return ''
    }
  }
})
