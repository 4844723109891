










import Vue from 'vue'
import { About, Tutorial, Redeem, Evidence, Header } from '@/components/Home'
export default Vue.extend({
  name: 'Home',
  components: {
    Header,
    About,
    Tutorial,
    Evidence,
    Redeem
  },
  methods: {
  }
})
