

































import Vue from 'vue'
export default Vue.extend({
  name: 'About',
  data: () => ({
    mobile: false
  }),
  created () {
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      this.mobile = window.innerWidth < 768
    }
  }
})
