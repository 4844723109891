/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
export default class API {
  private static BaseApi = 'https://poste.api.chainblock.it/'

  public static async SendToMint (token: string, wallet: string, email: string) : Promise<boolean> {
    try {
      const apiUri = `${API.BaseApi}request`
      const data = {
        token: token.toUpperCase(),
        wallet: wallet,
        email: email
      }
      const header = {
        'Content-Type': 'application/json'
      }
      const call = await axios.post(apiUri, data, {
        headers: header
      })
      if (call.status === 200) {
        return true
      }
      return false
    } catch (e: any) {
      const error = e as Error
      console.error(error)
      return false
    }
  }
}
