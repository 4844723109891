























































import Vue from 'vue'
import * as Redeem from '@/helpers/Home/Redeem'
import API from '@/helpers/api'
export default Vue.extend({
  name: 'Redeem',
  data: () => ({
    token: {
      disable: false,
      value: ''
    },
    wallet: {
      disable: false,
      value: ''
    },
    email: {
      disable: false,
      value: ''
    },
    state: 'default'
  }),
  mounted () {
    this.loadQueryParams()
  },
  methods: {
    async SendToMint () {
      if (this.state === 'success') return
      this.state = 'loader'
      const res = await API.SendToMint(this.token.value, this.wallet.value, this.email.value)
      if (res) {
        this.state = 'success'
      } else {
        this.state = 'fail'
      }
    },
    async generateWallet () {
      await Redeem.GenerateWallet(this.token.value, true)
    },
    loadQueryParams () {
      if (this.$route.query.token) {
        this.token.disable = true
        this.token.value = this.$route.query.token as string
      } else {
        this.token.disable = false
        this.token.value = ''
      }
      if (this.$route.query.wallet) {
        this.wallet.disable = true
        this.wallet.value = this.$route.query.wallet as string
      } else {
        this.wallet.disable = false
        this.wallet.value = ''
      }
      if (this.$route.query.focus) {
        if (this.$refs.email) {
          const emailHtml = this.$refs.email as HTMLElement
          emailHtml.classList.add('focusPulse')
          emailHtml.focus()
          setTimeout(() => {
            emailHtml.classList.remove('focusPulse')
          }, 2000)
        }
      }
    },
    NavToTutorial () {
      const ref = '#tutorial'
      if (location.hash === ref) {
        location.hash = '#'
      }
      location.hash = ref
    },
    async CloseToast () {
      location.hash = ''
      window.history.pushState({}, document.title, window.location.pathname)
      this.token = {
        disable: false,
        value: ''
      }
      this.wallet = {
        disable: false,
        value: ''
      }
      this.email = {
        disable: false,
        value: ''
      }
      this.state = 'default'
    }
  }
})
