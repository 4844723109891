









































import Vue from 'vue'
export default Vue.extend({
  name: 'Footer',
  data: () => ({
    year: new Date().getFullYear()
  })
})
