












































import Vue from 'vue'
import { RouteConfig } from 'vue-router'
import { routes } from '@/router'
export default Vue.extend({
  name: 'Navbar',
  data: () => ({
    mobile: false
  }),
  mounted () {
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      this.mobile = window.innerWidth < 768
      if (!this.mobile) {
        const MobileMenu = this.$refs.MobileMenu as HTMLElement
        MobileMenu.classList.remove('active')
      }
    },
    getRoutes: function () {
      return routes.filter(route => route.meta?.showNavbar !== false)
    },
    isActive: function (path: string) : string {
      if (this.$route.path !== path) return ''
      return 'active'
    },
    secondLineExist (route: RouteConfig) : boolean {
      return route.meta?.secondLine !== undefined
    },
    secondLine (route: RouteConfig) : string {
      if (route.meta?.secondLine) {
        return route.meta.secondLine
      }
      return ''
    },
    ToggleMenu () {
      const MobileMenu = this.$refs.MobileMenu as HTMLElement
      MobileMenu.classList.toggle('active')
      // const hamburger = this.$refs.HeaderHamburger as HTMLElement
      // hamburger.classList.toggle('collapsed')
      // const menu = this.$refs.Menu as HTMLElement
      // menu.classList.toggle('active')
    }
  }
})
