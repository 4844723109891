import * as bip39 from 'bip39'
import { GeneratePDF } from './GeneratePDF'
import { hdkey } from 'ethereumjs-wallet'
export interface Account {
  address: string;
  privateKey: string;
  mnemonic: string;
}
export default async function GenerateWallet (token : string, save = true) : Promise<string | undefined | never> {
  const wallet = await newWallet()
  return await GeneratePDF(wallet, token, save)
}

async function newWallet () : Promise<Account> {
  // bip39.setDefaultWordlist('italian')
  const phrase = bip39.generateMnemonic()
  const seed = await bip39.mnemonicToSeed(phrase)
  const derivationPath = "m/44'/60'/0'/0/0"
  const HDW = hdkey.fromMasterSeed(seed)
  const wallet = HDW.derivePath(derivationPath).getWallet()
  const address = wallet.getChecksumAddressString()
  const privateKey = wallet.getPrivateKeyString()
  const account: Account = {
    address: address,
    privateKey: privateKey,
    mnemonic: phrase
  }
  return account
}
