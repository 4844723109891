import qrcode from 'qrcode'
import Jspdf from 'jspdf'
import { Account } from './GenerateWallet'

export async function GeneratePDF (account: Account, token : string, save = true) : Promise<string | undefined> {
  const width = 620
  const height = 887
  const doc = new Jspdf({ orientation: 'portrait', compress: true, unit: 'px', format: [width, height] })
  const logoPoste = new Image()
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const logoPosteRaw = require('@/assets/images/pdf/logo.jpg')
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const logoCBKRaw = require('@/assets/images/pdf/cbklogo.png')
  const logoCBKRawDimension = { width: 120, height: 30 }
  const baseX = 40
  let baseY = baseX
  const halfWidth = width / 2
  const font = 'helvetica'
  logoPoste.src = logoPosteRaw
  baseY = 120
  doc.addImage(logoPoste, 'JPEG', baseX, baseX, baseY + 33, 42)
  doc.setFont(font, 'bold')
  doc.text('CREDENZIALI WALLET BLOCKCHAIN:', halfWidth, baseY, { align: 'center' })
  doc.text('ACCEDI AL TUO WALLET PERSONALE ED OTTIENI IL TUO NFT', halfWidth, baseY + 18, { align: 'center' })
  doc.setFont(font, 'normal')
  doc.setFontSize(12)
  baseY = 180
  doc.text('Gentite utente,', baseX, baseY)
  doc.text('Poste Italiane ha proceduto alla creazione del wallet blockchain, il tuo portafoglio digitale in cui potrai conservare i tuoi NFT.', baseX, baseY + 12)
  // QR Address e Private
  const qrBaseX = baseX + 80
  const qrCodeSize = 60
  const qrAddress = await getSVGQrCode(account.address, { width: qrCodeSize, color: { dark: '#00153b' } })
  baseY += 30
  doc.addImage(qrAddress, 'PNG', qrBaseX - qrCodeSize - 10, baseY, qrCodeSize, qrCodeSize)
  doc.text('Questo è l’indirizzo pubblico del tuo wallet:', qrBaseX, baseY + 10)
  doc.setFont(font, 'bold').setTextColor('#0047bb')
  doc.text(account.address, qrBaseX, baseY + 30)
  doc.setFont(font, 'normal').setTextColor('#000000')
  doc.text('Potrai utilizzarlo anche in futuro per ricevere altri token o NFT.', qrBaseX, baseY + 50)
  baseY += 80
  const qrPrivate = await getSVGQrCode(account.privateKey, { width: qrCodeSize, color: { dark: '#00153b' } })
  doc.addImage(qrPrivate, 'PNG', qrBaseX - qrCodeSize - 10, baseY, qrCodeSize, qrCodeSize)
  doc.text('Di seguito è riportata la tua chiave privata:', qrBaseX, baseY + 10)
  doc.setFont(font, 'bold').setTextColor('#0047bb')
  doc.text(account.privateKey, qrBaseX, baseY + 30)
  doc.setFont(font, 'normal').setTextColor('#000000')
  doc.setFontSize(10)
  doc.text('Ti servirà per effettuare ogni tipo di transazione in blockchain, rappresenterà la tua firma digitale e dunque il tuo consenso,', qrBaseX, baseY + 45)
  doc.text('non condividerla con nessuno e mantienila in un luogo sicuro.', qrBaseX, baseY + 55)
  baseY += 70
  doc.setFontSize(9)
  doc.text('Inoltre, scannerizzando quest’ultimo QR code, tramite l’app Metamask dal tuo cellulare, puoi importare direttamente il tuo wallet.', baseX + 10, baseY)
  doc.setFontSize(12)
  baseY += 20
  doc.text('Se perdi o dimentichi le tue chiavi (sia la pubblica che la privata), puoi recuperare il wallet attraverso la seguente Seed phrase.', baseX, baseY)
  doc.setFont(font, 'bold').setTextColor('#000000').setFontSize(16)
  doc.text(account.mnemonic, baseX, baseY + 20)
  doc.setFont(font, 'normal').setTextColor('#000000').setFontSize(12)
  const seedPhrase = 'La seed phrase è una frase composta da 12 parole attraverso la quale hai la possibilità di recuperare l’accesso al tuo wallet in qualsiasi momento su Metamask. È estremamente importante conservarla in un luogo sicuro (ti consigliamo di annotarla in formato cartaceo) e non condividerla con nessuno.'
  const seedPhraseLines = doc.splitTextToSize(seedPhrase, (width - (baseX * 2)))
  doc.text(seedPhraseLines, baseX, baseY + 40)
  baseY += 80
  doc.text('Prima di procedere con il link sottoelencati, assicurati di aver salvato questo file pdf sul tuo device o di aver annotato le tue chiavi:', baseX, baseY)
  baseY += 30
  doc.setFontSize(16).setFont(font, 'bold')
  doc.setTextColor('#0047bb')
  doc.textWithLink('CLICCA QUI', width / 2, baseY, { align: 'center', url: getUrl(account.address, token) })
  doc.setTextColor('#000000')
  doc.setFontSize(12).setFont(font, 'normal')
  baseY += 12
  doc.text('per tornare alla pagina precedente dove poter inserire la mail ed ottenere l’NFT', width / 2, baseY, { align: 'center' })
  baseY += 30
  const outro = 'Poste Italiane ringrazia per aver partecipato a tale iniziativa. Ribadisce inoltre l’impegno e l’attenzione nel curare l’esperienza di acquisto dei propri clienti, attraverso contenuti dedicati e prodotti innovativi.'
  const outroSplit = doc.splitTextToSize(outro, width - (baseX * 2))
  doc.text(outroSplit, baseX, baseY)
  baseY += outroSplit.length * 12
  baseY += 10
  doc.setFont(font, 'italic')
  doc.text('Poste Italiane - Filatelia', baseX, baseY)
  baseY = height - logoCBKRawDimension.height - 30
  doc.addImage(logoCBKRaw, 'PNG', (width - logoCBKRawDimension.width) / 2, baseY, logoCBKRawDimension.width, logoCBKRawDimension.height)
  baseY = height - 20
  doc.textWithLink('Powered By Chainblock™', width / 2, baseY, { align: 'center', url: 'https://www.chainblock.it' })
  if (save) {
    doc.save('Wallet.pdf')
  } else {
    const string = doc.output('datauristring')
    return string
  }
}

function getUrl (address: string, token: string) : string {
  const rawUrl = window.location.href
  const urlObj = new URL(rawUrl)
  if (!urlObj.searchParams.has('token')) {
    urlObj.searchParams.append('token', token)
  }
  urlObj.hash = 'token'
  urlObj.searchParams.append('wallet', address)
  urlObj.searchParams.append('focus', 'email')
  return urlObj.toString()
}
async function getSVGQrCode (text:string, options?: {
  width?: number,
  margin?: number,
  color?: {
    dark: string
  }
}): Promise<string> {
  const defaultOpt = {
    width: 70,
    margin: 1,
    color: {
      dark: '#000000'
    }
  }
  const opt = { ...defaultOpt, ...options }
  return await qrcode.toDataURL(text, {
    type: 'image/png',
    ...opt
  })
}
