import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/faq',
    name: 'FAQ e',
    meta: {
      secondLine: 'Glossario'
    },
    component: () => import(/* webpackChunkName: "FAQ" */ '../views/FAQ.vue')
  },
  {
    path: '/tutorial',
    name: 'Video',
    meta: {
      secondLine: 'Tutorial'
    },
    component: () => import(/* webpackChunkName: "videos" */ '../views/Videos.vue')
  },
  {
    path: '/dev/internal/test',
    name: 'test',
    meta: {
      showNavbar: false
    },
    component: () => import(/* webpackChunkName: "TEST" */ '../views/TEST.vue')
  },
  {
    path: '*',
    name: '404',
    meta: {
      showNavbar: false
    },
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
