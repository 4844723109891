import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Accordion from '@/components/FAQ/Accordion.vue'
export const AccordionBus = new Vue()
Vue.config.productionTip = false
Vue.component('Accordion', Accordion)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
