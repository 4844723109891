



import Vue from 'vue'
export default Vue.extend({
  name: 'BackToTop',
  data: () => ({
    css: ''
  }),
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    setTimeout(() => {
      if (that.$refs.btn !== undefined) {
        const el = that.$refs.btn as HTMLElement
        el.style.display = 'block'
      }
    }, 1500)
  },
  methods: {
    handleScroll () : void {
      if (window.scrollY > (window.innerHeight / 2)) {
        this.css = 'visible'
      } else {
        this.css = ''
      }
    },
    ToTop () : void {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
})
