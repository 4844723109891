























import Vue from 'vue'
export default Vue.extend({
  name: 'Header',
  methods: {
    click: function () {
      if (location.hash === '#about') {
        location.hash = '#'
      }
      location.hash = '#about'
    }
  }
})
